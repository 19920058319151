import Api from "@/Services/Api";

export default {
    async getHomepageMessageList() {
    let response = await Api().get("/support-vision/homepage-message")
    return response.data
    },
    async postHomepageMessage(data) {
        let response = await Api().post("/support-vision/homepage-message", {homepageMessage: data})
        return response.data
    },
    async putHomepageMessage(data) {
        let response = await Api().put("/support-vision/homepage-message", {homepageMessage: data})
        return response
    },
    async removeHomepageMessage(id) {
        let response = await Api().delete("/support-vision/homepage-message", {params: {homepageMessage: id}})
        return response
    }
}