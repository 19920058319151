<template>
  <div>
    <template v-if="isPilotClient">
      <v-menu>
        <template #activator="{attrs, on}">
          <v-btn icon v-on="on" v-bind:attrs="attrs">
            <v-icon>mdi-book-open-variant</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="downloadGuide('interface')">
            <v-list-item-action>
              <v-icon>mdi-book-open-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t("app.topbar.userGuideInterface") }}</v-list-item-title>

          </v-list-item>
          <v-list-item link @click="downloadGuide('hotline')">
            <v-list-item-action>
              <v-icon>mdi-book-open-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t("app.topbar.userGuideHotline") }}</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </template>
    <template v-else>
      <v-btn icon @click="downloadGuide('interface')" target="_blank">
        <v-icon>mdi-book-open-variant</v-icon>
      </v-btn>
    </template>

  </div>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import {SVSHotline} from "@/Services/SupportVisionService";

export default {
  name: "UserGuide",
  data() {
    return {
      isPilotClient: false
    }
  },
  methods: {
    async downloadGuide(type = "interface") {
      if (type == "interface")
        await PlaneteOnlineService.getGuide()
      else if (type == "hotline")
        await PlaneteOnlineService.getHotlineGuide()
    },
  },
  async mounted() {
    this.isPilotClient = await SVSHotline.hasAccessToHotline(this.getDns())
  }
}
</script>

<style scoped>

</style>