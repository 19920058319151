import Api from "@/Services/Api";
import moment from "moment";

export default {
    async getHotlineAccessList() {
        let response = await Api().get("/support-vision/hotline-access")
        return response.data
    },
    async getAllHotlineAccessList() {
        let response = await Api().get("/support-vision/hotline-access/hotline-access-all")
        return response.data
    },
    async postHotlineAccess(data) {
        let response = await Api().post("/support-vision/hotline-access", {hotlineAccess: data})
        return response.data
    },
    async putHotlineAccess(data) {
        let response = await Api().put("/support-vision/hotline-access", {hotlineAccess: data})
        return response
    },
    async removeHotlineAccess(id) {
        let response = await Api().delete("/support-vision/hotline-access", {params: {hotlineAccess: id}})
        return response
    },
    async hasAccessToHotline(dns) {
        let hotlineAccess = await this.getHotlineAccessList()
        hotlineAccess = hotlineAccess.filter(access => {
            if (access.start == null && access.end == null)
                return true

            if (access.end == null)
                return moment(access.start, "YYYY-MM-DD") <= moment()
            if (access.start == null)
                return moment(access.end, "YYYY-MM-DD") >= moment()

            return  moment(access.start, "YYYY-MM-DD") <= moment()
                && moment(access.end, "YYYY-MM-DD") >= moment().startOf("day")
        })

        hotlineAccess = hotlineAccess.find(access => {
            let urls = access.list.map(base => base.name)
            return (urls.indexOf(dns) !== -1)
        })
        return !!hotlineAccess && hotlineAccess.active
    }
}