import Api from "@/Services/Api";

export default {
    async getAuthenticationConfiguration() {
    let response = await Api().get("/support-vision/authentication-configuration")
    return response.data
    },
    async getAuthenticationConfigurationLite() {
    let response = await Api().get("/support-vision/authentication-configuration/lite")
    return response.data
    },
    async postAuthenticationConfiguration(data) {
        let response = await Api().post("/support-vision/authentication-configuration", {authenticationConfiguration: data})
        return response.data
    },
    async putAuthenticationConfiguration(data) {
        let response = await Api().put("/support-vision/authentication-configuration", {authenticationConfiguration: data})
        return response
    },
    async removeAuthenticationConfiguration(id) {
        let response = await Api().delete("/support-vision/authentication-configuration", {params: {authenticationConfiguration: id}})
        return response
    }
}